
/*
================================================================================
|                                     FOOTER                                   |
================================================================================
*/
.footer {	
	text-align: left;
	width:100%;
	min-width: $min-width;
	order: 3;	
    -webkit-order: 3;position: relative; overflow: hidden; 
 	
}

.footer_cols {display: flex; justify-content: space-between; padding: 8.9rem 0 1.4rem; background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABSgAAAAICAYAAAD6BREZAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACuSURBVHgB7dCxAcAgDMRANk92sz0XrEDFN6f6Kq110cx83b2r6ud5nud5nud5nud5nud5nn/mzeF5nud5nud5nud5nud5PuLN4Xme53me53me53me53k+4s3heZ7neZ7neZ7neZ7neT7izeF5nud5nud5nud5nud5PuLN4Xme53me53me53me53k+4s3heZ7neZ7neZ7neZ7neT7izeF5nud5nud5nud5nud5/rU/T2aKowIR87oAAAAASUVORK5CYII=) no-repeat top;
	&_item {margin-bottom: 5rem;}
	&_item-1 {min-width: 30.3%; width: 30.3%; margin-right: 5rem;}
	&_item-2 {flex-grow:1; margin-right: 5rem;}
	&_item-3 {min-width: 23.2%;} 
} 
.footer_title {padding-bottom: 1.2rem; margin-bottom: 2.4rem; font-family: $title-font-family; font-weight: 500;font-size: 2rem;line-height: 130%;text-transform: capitalize;border-bottom: 1px solid rgba(0, 0, 0, 0.1);color: #000000;}
.footer_txt {font-size: 1.6rem;line-height: 163%;color: #555555;}
.footer_list {list-style: none; column-count: 3; margin-right: -11.5%;margin-top: 2.5rem;
	li {font-weight: 600;font-size: 1.2rem;line-height: 250%;text-transform: uppercase;
		a {color: #555555;
			&:hover {color:#F55C6E;}
		}
	}
}
.footer_bot {padding: 2.4rem 0; margin-top: 2.8rem; font-size: 1.4rem; display: flex; justify-content: space-between; align-items: center;border-top:2px solid #000;}
.footer_copy {}
.footer_links {
	a {color: #555555;
		&:hover {color:#F55C6E;}
	}
	.dash {margin: -3px 7px 0 7px;}
}

.soc { display: flex; align-items: flex-start; margin-right: -1rem;
	&_item {margin: 0 1rem 0 0;
		a {display: inline-block;text-align: center;color: #555555;
			&:hover .soc_item_icon {color:#F55C6E;}
		}
		&_icon {font-size: 2.2rem; background: #EFF2FB; margin:0 auto 4px; width: 6rem;height: 6rem; border-radius: 50%;display: flex; align-items: center; justify-content: center;
			.icon-youtube {font-size: 1.8rem;}
			.icon-instagram {font-size: 2.4rem;}
		}
		&_txt {font-size: 1.2rem;line-height: 160%;}
	}
}


@media screen and (max-width: 1199px) {
	.footer_cols_item-1 { margin-right: 4rem;}
	.footer_cols_item-2 { margin-right: 4rem;}
	.footer_list { margin-right: -7.5%;}

}
@media screen and (max-width: 1023px) {
	.footer_cols {display: block; padding: 7.5rem 0 0rem;
		&_item-1 {width: 100%; margin-right: 0rem;}
		&_item-2 {margin-right: 0rem;}
		&_item-3 {min-width: 100%;} 
	}
}

@media screen and (max-width: 1023px) and (min-width: 600px) {
	
}
@media screen and (max-width: 767px) {
	.footer_cols_item { margin-bottom: 3.5rem;}
	.footer_cols { padding: 4rem 0 0rem;}
}

@media screen and (max-width: 599px){
	.footer_list {column-count: 2;}
	.footer_bot {display: block;}
	.footer_copy {margin-bottom: 0.5rem;}

}
@media screen and (max-width: 599px) and (orientation: landscape){
	.footer_list {column-count: 3;}
}
